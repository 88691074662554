import router from "@/router";
import projectStates from "@/routing/core/Config/Workflow/ProjectStates/ProjectStates.vue";

export const state = {
  filters: {
    customers: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "multilist",
        array: [],
        value: "",
        labelType: 'title',
        source: 'categories_customers'
      },
      type: {
        name: 'type',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: "customerTypes",
      },
      country: {
        name: 'country',
        type: "multilist",
        array: [],
        labelType: 'lang_name',
        value: "",
        source: "countriesFilter",
      },
      state: {
        name: 'state',
        type: "multilist",
        array: [],
        labelType: 'label',
        value: "",
        source: [
          {label: 'Aktiv', id: '1'},
          {label: 'Inaktiv', id: '3'},
          {label: 'Archiv', id: '2'},
          {label: 'Papierkorb', id: '4'},
          {label: 'Verstorben', id: '5'},
        ],
      },
      creationDateFrom: {
        name: 'creationDateFrom',
        type: "datetime",
        value: "",
        source: null,
      },
      creationDateTo: {
        name: 'creationDateTo',
        type: "datetime",
        value: "",
        source: null,
      },
      umsatz: {
        name: 'umsatz',
        type: "list",
        labelType: 'name',
        value: "",
        source: "tbd",
      },
      akquise: {
        name: 'akquise',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: "customerAcquisition",
      },
      prio: {
        name: 'prio',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: "customerPrio",
      },
      lead: {
        name: 'lead',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: "customerLead",
      },
      structure1: {
        name: 'structure1',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: "customerStructure_1",
      },
      structure2: {
        name: 'structure2',
        type: "multilist",
        labelType: 'name',
        array: [],
        value: "",
        source: "customerStructure_2",
      },
      structure3: {
        name: 'structure3',
        type: "multilist",
        labelType: 'name',
        array: [],
        value: "",
        source: "customerStructure_3",
      },
      structure4: {
        name: 'structure4',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: "customerStructure_4",
      },
      report: {
        name: 'report',
        type: "list",
        labelType: 'name',
        value: "",
        structure: [],
        source: "customerReport",
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "textinput",
        value: "",
        source: "title",
      },
      owner_id: {
        name: 'owner_id',
        type: "multilist",
        labelType: 'e_name',
        value: "",
        array: [],
        source: "employees",
      },
      tags: {
        name: 'tags',
        type: "multilist",
        labelType: 'name',
        value: "",
        array: [],
        source: "customerTags",
      },
      sketchdates: {
        name: 'sketchdates',
        type: "list",
        value: "",
        labelType: 'title',
        source: "sketchdates",
      },
      sketchdates_state: {
        name: 'sketchdates_state',
        type: "list",
        value: "",
        labelType: 'label',
        source: [
          {label: 'Angemeldet + Warteliste', id: 'aw'},
          {label: 'Angemeldet', id: 'a'},
          {label: 'Warteliste', id: 'w'},
          {label: 'Storniert', id: 's'},
        ],
      },
      activeSEPA: {
        name: 'activeSEPA',
        type: "list",
        value: "",
        labelType: 'label',
        source: [
          {label: 'aktives SEPA-Mandat', id: '1'},
          {label: 'kein aktives SEPA-Mandat', id: '0'},
        ]
      },
      invoiceType: {
        name: 'invoiceType',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: [
          {name: 'Standard', id: '1'},
          {name: 'SEPA', id: '2'},
          {name: 'Skonto', id: '3'},
        ],
      },
      activeOpos: {
        name: 'activeOpos',
        type: 'list',
        value: "",
        labelType: 'name',
        source: [
          {name: 'offene Posten', id: '1'},
          {name: 'keine offenen Posten', id: '0'},
        ]
      },
      contractType: {
        name: 'contractType',
        type: "multilist",
        array: [],
        labelType: 'name',
        value: "",
        source: [
          {name: 'aktiver Vertrag', id: 'active'},
          {name: 'inaktiver Vertrag', id: 'inactive'},
          {name: 'kein Vertrag', id: 'none'},
        ],
      },
      holzkraft: {
        name: 'holzkraft',
        type: "list",
        value: "",
        labelType: 'title',
        source: "holzkraft",
      },
      plugin_holzkraft_project__state: {
        name: 'plugin_holzkraft_project__state',
        type: "list",
        labelType: 'title',
        value: "",
        source: "project_states",
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__gebrauchtanlage: {
        name: 'plugin_holzkraft_pHolz__gebrauchtanlage',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Ja', id: '1'},
          {label: 'Nein', id: '0'},
        ],
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__abschluss: {
        name: 'plugin_holzkraft_pHolz__abschluss',
        type: "fromtill",
        labelType: 'label',
        value: "",
        from: '',
        till: '',
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__gesamtkosten: {
        name: 'plugin_holzkraft_pHolz__gesamtkosten',
        type: "fromtill",
        labelType: 'label',
        value: "",
        from: '',
        till: '',
        plugin: 'holzkraft'
      },
      plugin_trainer__trainerVerified: {
        name: 'plugin_trainer__trainerVerified',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Verifiziert', id: 'verified'},
          {label: 'Abgelehnt', id: 'denied'},
          {label: 'Wartend', id: 'waiting'},
        ],
        plugin: 'trainer'
      },
      plugin_trainer__trainerLicenseNo: {
        name: 'plugin_trainer__trainerLicenseNo',
        type: "input",
        value: "",
        plugin: 'trainer'
      },
      plugin_trainer__trainerLicense: {
        name: 'plugin_trainer__trainerLicense',
        type: "input",
        value: "",
        plugin: 'trainer'
      },
    },
    category: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
    },
    queues: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "doc_d_date",
        source: null
      },
      todo_state: {
        name: 'todo_state',
        type: "list",
        value: '',
        labelType: 'label',
        source: [{label: 'In Warteschlange', id: 'executable'},
          {label: 'In Vorbereitung', id: 'queued'},
          {label: 'Erledigt', id: 'finished'},
          {label: 'Abgebrochen', id: 'failed'},
          {label: 'Gestoppt', id: 'stopped'}
        ],
      },
      type: {
        name: 'type',
        type: "list",
        value: "",
        labelType: 'label',
        source: [
          {label: 'Dokument', id: 'document'},
          {label: 'Mitarbeiter', id: 'employee'},
          {label: 'Kunde', id: 'customer'},
          {label: 'Aufgabe', id: 'task'},
        ],
      },
      from: {
        name: 'from',
        type: "datetime",
        value: "",
        source: null,
      },
      till: {
        name: 'till',
        type: "datetime",
        value: "",
        source: null,
      }
    },
    employees: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    timeConfigs: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    issueTracker: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    issueStates: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    projectStates: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    issuePrio: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    issueActivities: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    products: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_products'
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "p_title",
        source: null
      },
    },
    tasks: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "",
        source: null
      },
      state: {
        name: 'state',
        type: 'list',
        labelType: 'label',
        value: 'progress',
        source: [
          {label: 'Offen', id: 'open'},
          {label: 'Warten auf Kunde', id: 'waiting'},
          {label: 'Wiederholend', id: 'reject'},
          {label: 'Erledigt', id: 'closed'},
          {label: 'Produktiv', id: 'progress'},
          {label: 'Alle', id: 'all'},
          {label: 'Ohne Zuweisung', id: 'unassigned'},
        ],
      },
      employee: {
        name: 'employee',
        type: "list",
        value: "",
        labelType: 'e_name',
        source: "employees",
      },
      priority: {
        name: 'priority',
        type: "list",
        labelType: 'title',
        value: "",
        source: 'issue_prios'
      },
      state_id: {
        name: 'state_id',
        type: "list",
        labelType: 'title',
        value: "",
        source: "issue_states",
      },
      tracker: {
        name: 'tracker',
        type: 'list',
        labelType: 'title',
        value: '',
        source: 'issue_trackers'
      },
      customer: {
        name: 'customer',
        type: "customer",
        labelType: 'title',
        fullCustomer: '',
        value: "",
        source: ''
      },
      project: {
        name: 'project',
        type: "project",
        labelType: 'title',
        value: "",
        source: ''
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "",
        source: null
      },
    },
    sepas: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
      active: {
        name: 'active',
        type: "list",
        value: "",
        labelType: 'name',
        source: "tbd",
      }
    },
    legals: {
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    bankAccounts: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    accounting: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      fromDate: {
        name: 'fromDate',
        type: "datetime",
        value: "",
        source: null,
      },
      tillDate: {
        name: 'tillDate',
        type: "datetime",
        value: "",
        source: null,
      }
    },
    accountingKeys: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    costNumbers: {
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    customerTaxes: {
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    yearPlan: {
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    drafts: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_drafts'
      },
      system: {
        name: 'system',
        type: "list",
        value: "user",
        labelType: 'title',
        source: [
          {
            title: 'Benutzer-Vorlagen',
            id: 'user'
          },
          {
            title: 'System-Vorlagen',
            id: 'system'
          }
        ]
      },
      state: {
        name: 'state',
        type: "list",
        value: "1",
        labelType: 'label',
        source: [
          {label: 'Aktiv', id: '1'},
          {label: 'Archiv', id: '-1'},
          {label: 'Papierkorb', id: '-2'},
        ],
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    documents: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      category: {
        name: 'category',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_documents'
      },
      docstate: {
        name: 'docstate',
        type: "list",
        value: "",
        labelType: 'label',
        source: [
          {label: 'Aktuelle', id: '0'},
          {label: 'Archivierte', id: '1'},
          {label: 'Zu Versenden', id: '4'},
          {label: 'Passiv-Dokumente', id: '8'},
          {label: 'Verträge in Bearbeitung', id: '19'},
        ],
      },
      owner: {
        name: 'owner',
        type: "list",
        labelType: 'e_name',
        value: "",
        source: "employees",
      },
      creationDateFrom: {
        name: 'creationDateFrom',
        type: "datetime",
        value: "",
        source: null,
      },
      creationDateTo: {
        name: 'creationDateTo',
        type: "datetime",
        value: "",
        source: null,
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "doc_d_date",
        source: null
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
    },
    projects: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "doc_d_date",
        source: null
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      owner_id: {
        name: 'owner_id',
        type: "list",
        labelType: 'e_name',
        value: "",
        source: "employees",
      },
      state: {
        name: 'state',
        type: "list",
        labelType: 'title',
        value: "",
        source: "project_states",
      },
      issue_state: {
        name: 'issue_state',
        type: "list",
        value: "",
        labelType: 'label',
        source: [
          {label: 'Hat offene Tickets', id: 'has_open_tickets'},
          {label: 'Alles erledigt', id: 'all_closed_tickets'},
          {label: 'Hat keine Tickets', id: 'has_no_tickets'}
        ],
      },
      customer: {
        name: 'customer',
        type: "customer",
        labelType: 'title',
        fullCustomer: '',
        value: "",
        source: ''
      },
      holzkraft: {
        name: 'holzkraft',
        type: "list",
        value: "",
        labelType: 'title',
        source: "holzkraft",
      },
      plugin_holzkraft_pHolz__vertriebsplanung: {
        name: 'plugin_holzkraft_pHolz__vertriebsplanung',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Ja', id: '1'},
          {label: 'Nein', id: '0'},
        ],
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__gebrauchtanlage: {
        name: 'plugin_holzkraft_pHolz__gebrauchtanlage',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Ja', id: '1'},
          {label: 'Nein', id: '0'},
        ],
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__abschluss: {
        name: 'plugin_holzkraft_pHolz__abschluss',
        type: "fromtill",
        labelType: 'label',
        value: "",
        from: '',
        till: '',
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__gesamtkosten: {
        name: 'plugin_holzkraft_pHolz__gesamtkosten',
        type: "fromtill",
        labelType: 'label',
        value: "",
        from: '',
        till: '',
        plugin: 'holzkraft'
      },
    },
    reports: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "doc_d_date",
        source: null
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      owner_id: {
        name: 'owner_id',
        type: "list",
        labelType: 'e_name',
        value: "",
        source: "employees",
      },
      state: {
        name: 'state',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'In Vorbereitung', id: 'prepare'},
          {label: 'Angebot', id: 'offer'},
          {label: 'Auftrag erhalten', id: 'order'},
          {label: 'Läuft aktuell', id: 'progress'},
          {label: 'Erledigt', id: 'finished'},
          {label: 'Gelöscht', id: 'deleted'},
        ],
      },
      holzkraft: {
        name: 'holzkraft',
        type: "list",
        value: "",
        labelType: 'title',
        source: "holzkraft",
      },
      plugin_holzkraft_project__state: {
        name: 'plugin_holzkraft_project__state',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'In Vorbereitung', id: 'prepare'},
          {label: 'Angebot', id: 'offer'},
          {label: 'Auftrag erhalten', id: 'order'},
          {label: 'Läuft aktuell', id: 'progress'},
          {label: 'Erledigt', id: 'finished'},
          {label: 'Gelöscht', id: 'deleted'},
        ],
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__vertriebsplanung: {
        name: 'plugin_holzkraft_pHolz__vertriebsplanung',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Ja', id: '1'},
          {label: 'Nein', id: '0'},
        ],
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__gebrauchtanlage: {
        name: 'plugin_holzkraft_pHolz__gebrauchtanlage',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Ja', id: '1'},
          {label: 'Nein', id: '0'},
        ],
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__abschluss: {
        name: 'plugin_holzkraft_pHolz__abschluss',
        type: "fromtill",
        labelType: 'label',
        value: "",
        from: '',
        till: '',
        plugin: 'holzkraft'
      },
      plugin_holzkraft_pHolz__gesamtkosten: {
        name: 'plugin_holzkraft_pHolz__gesamtkosten',
        type: "fromtill",
        labelType: 'label',
        value: "",
        from: '',
        till: '',
        plugin: 'holzkraft'
      },
    },
    events: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
        ordering: {
          name: 'ordering',
          type: "list",
          value: "doc_d_date",
          source: null
        },
        direction: {
          name: 'direction',
          type: "list",
          value: "desc",
          source: null
        },
      },
      category_id: {
        name: 'category_id',
        type: "list",
        value: "",
        labelType: 'title',
        source: 'categories_events'
      },
      state: {
        name: 'state',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Versteckt', id: 0},
          {label: 'Veröffentlicht', id: 1}
        ],
      },
      aktuell: {
        name: 'aktuell',
        type: "list",
        labelType: 'label',
        value: '',
        source: [
          {label: 'Alle anzeigen', id: '3'},
          {label: 'Aktuelle anzeigen', id: '1'},
          {label: 'Vergangene anzeigen', id: '2'},
        ],
      },
      datefrom: {
        name: 'datefrom',
        type: "datetime",
        value: "",
        source: null,
      },
      dateto: {
        name: 'dateto',
        type: "datetime",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "",
        source: null
      },
    },
    newsletters: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
        ordering: {
          name: 'ordering',
          type: "list",
          value: "id",
          source: null
        },
        direction: {
          name: 'direction',
          type: "list",
          value: "desc",
          source: null
        },
      },
      state: {
        name: 'state',
        type: "list",
        labelType: 'label',
        value: "",
        source: [
          {label: 'Versteckt', id: 0},
          {label: 'Veröffentlicht', id: 1}
        ],
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "",
        source: null
      },
    },
    languageOverrides: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
    },
    attests: {
      search: {
        name: 'search',
        type: "textinput",
        value: "",
        source: null,
      },
      direction: {
        name: 'direction',
        type: "list",
        value: "desc",
        source: null
      },
      ordering: {
        name: 'ordering',
        type: "list",
        value: "title",
        source: null
      },
      datefrom: {
        name: 'datefrom',
        type: "datetime",
        value: '',
        source: null,
      },
      license: {
        name: 'license',
        type: "list",
        value: "",
        labelType: 'label',
        source: [
          {label: 'A-Lizenz', id: 'a'},
          {label: 'B-Lizenz', id: 'b'},
        ],
      },
      state: {
        name: 'state',
        type: "list",
        value: "",
        labelType: 'label',
        source: [
          {label: 'Aktiv', id: '1'},
          {label: 'Inaktiv', id: '0'},
        ],
      },
      type: {
        name: 'type',
        type: 'list',
        value: '',
        labelType: 'label',
        source: [
          {label: 'Führungszeugnis', id: 'efz'},
          {label: 'Attest', id: 'attest'},
        ],
      },
      phase:{
        name: 'phase',
        type: 'list',
        value: '',
        labelType: 'label',
        source: [
          {label: 'Phase 1', id: '1'},
          {label: 'Phase 2', id: '2'},
        ],
      },
      approvalstate: {
        name: 'approvalstate',
        type: 'list',
        value: '',
        labelType: 'label',
        source: [
          {label: 'Genehmigt', id: '1'},
          {label: 'Abgelehnt', id: '0'},
          {label: 'Wartend', id: '2'},
        ],
      },
    },

    tempProjects: [],
  },
}
export const mutations = {
  // Mutationen für drafts
  addFilter(state, payload) {
    state.filters[payload.group][payload.name] = payload;
  }
}
export const actions = {
  customersFilter({dispatch, state, getters}) {
    let vars = router.currentRoute.value.query;
    if (vars.length && vars.length > 0) {
      // reset active filters
      for (let key in state.filters.customers) {
        state.filters.customers[key].value = '';
      }
    }
    for (let key in vars) {
      if (key.indexOf('filter_') !== -1) {
        let filterName = key.replace('filter_', '');
        if (state.filters.customers[filterName] !== undefined) {
          state.filters.customers[filterName].value = vars[key];
        }
      }
    }
    // router.push("/customers");
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.customers) {
      if (state.filters.customers[a].value !== null && state.filters.customers[a].value !== '') {
        if (state.filters.customers[a].group === 'customers') {
          filter_query += '&cf_' + state.filters.customers[a].name + '=' + encodeURIComponent(state.filters.customers[a].value);
        } else {
          if (typeof state.filters.customers[a].value === 'object') {
            filter_query += '&filter_' + state.filters.customers[a].name + '=' + state.filters.customers[a].value.id;
          } else {
            filter_query += '&filter_' + state.filters.customers[a].name + '=' + encodeURIComponent(state.filters.customers[a].value);
          }
        }
      } else if (state.filters.customers[a].array && state.filters.customers[a].array.length > 0) {
        let b;
        for (b in state.filters.customers[a].array) {
          filter_query += '&filter_' + state.filters.customers[a].name + '%5B%5D=' + state.filters.customers[a].array[b];
        }
      } else if (state.filters.customers[a].from || state.filters.customers[a].till) {
        filter_query += '&filter_' + state.filters.customers[a].name + '__from=' + state.filters.customers[a].from + '&filter_' + state.filters.customers[a].name + '__till=' + state.filters.customers[a].till;
      }
    }
    //dispatch("customersGet", filter_query);
    const resource = 'customer';
    const apiRoute = resource;
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  resetFilter({state}, filterType) {
    for (let key in state.filters[filterType]) {
      state.filters[filterType][key].value = '';
      state.filters[filterType][key].array = [];
      state.filters[filterType][key].from = '';
      state.filters[filterType][key].till = '';
      state.lists[filterType].offset = 0;
    }
  },
  tasksFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.tasks) {
      if (state.filters.tasks[a].value !== null && state.filters.tasks[a].value !== '') {
        if (typeof state.filters.tasks[a].value === 'object') {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + state.filters.tasks[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + encodeURIComponent(state.filters.tasks[a].value);
        }
      }
    }
    // if filter_employee is empty, set default current state.user._isEmployee
    const resource = "task";
    const apiRoute = "issue";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  tasksFilterCustomer({dispatch, state}, filters) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.tasks) {
      if (state.filters.tasks[a].value !== null && state.filters.tasks[a].value !== '') {
        if (typeof state.filters.tasks[a].value === 'object') {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + state.filters.tasks[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.tasks[a].name + '=' + encodeURIComponent(state.filters.tasks[a].value);
        }
      }
    }
    if (state.activeItems && state.activeItems.customer && state.activeItems.customer.id) {
      filter_query += '&filter_customer=' + state.activeItems.customer.id;
    }
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const value = filters[key];
        if (typeof value !== 'object') {
          // Only append if the value is not an object
          filter_query += `&${key}=${value}`;
        }
      }
    }
    // if(state.user._isEmployee){
    //   filter_query += '&filter_employee=' + state.user._isEmployee;
    // }
    const resource = "task";
    const apiRoute = "issue";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  documentsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.documents) {
      if (state.filters.documents[a].value !== null && state.filters.documents[a].value !== '') {
        if (typeof state.filters.documents[a].value === 'object') {
          filter_query += '&filter_' + state.filters.documents[a].name + '=' + state.filters.documents[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.documents[a].name + '=' + encodeURIComponent(state.filters.documents[a].value);
        }
      }
    }
    if (state.filters.documents.docstate.value === '' || state.filters.documents.docstate.value === null) {
      filter_query += '&filter_docstate=0';
    }
    //dispatch("documentsGet", filter_query);
    const resource = "document";
    const apiRoute = "document";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  sepasFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.sepas) {
      if (state.filters.sepas[a].value !== null && state.filters.sepas[a].value !== '') {
        if (typeof state.filters.sepas[a].value === 'object') {
          filter_query += '&filter_' + state.filters.sepas[a].name + '=' + state.filters.sepas[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.sepas[a].name + '=' + encodeURIComponent(state.filters.sepas[a].value);
        }
      }
    }
    const resource = "sepa";
    const apiRoute = "sepa";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  accountingFilter({dispatch, state}) {
    console.log('accountingFilter');
  },
  productsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.products) {
      if (state.filters.products[a].value !== null && state.filters.products[a].value !== '') {
        if (typeof state.filters.products[a].value === 'object') {
          if (state.filters.products[a].name === 'category') {
            filter_query += '&filter_category_id=' + state.filters.products[a].value.id;
          } else {
            filter_query += '&filter_' + state.filters.products[a].name + '=' + state.filters.products[a].value.id;
          }
        } else {
          filter_query += '&filter_' + state.filters.products[a].name + '=' + encodeURIComponent(state.filters.products[a].value);
        }
      }
    }
    //dispatch("productsGet", filter_query);
    const resource = "product";
    const apiRoute = "product";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  legalsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.legals) {
      if (state.filters.legals[a].value !== null && state.filters.legals[a].value !== '') {
        if (typeof state.filters.legals[a].value === 'object') {
          filter_query += '&filter_' + state.filters.legals[a].name + '=' + state.filters.legals[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.legals[a].name + '=' + encodeURIComponent(state.filters.legals[a].value);
        }
      }
    }
    //dispatch("legalsGet", filter_query);
    const resource = "legal";
    const apiRoute = "legal";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  draftsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.drafts) {
      if (state.filters.drafts[a].value !== null && state.filters.drafts[a].value !== '') {
        if (typeof state.filters.drafts[a].value === 'object') {
          if (state.filters.drafts[a].name === 'category') {
            filter_query += '&group_id=' + state.filters.drafts[a].value.id;
          } else {
            filter_query += '&filter_' + state.filters.drafts[a].name + '=' + state.filters.drafts[a].value.id;
          }
        } else {
          filter_query += '&filter_' + state.filters.drafts[a].name + '=' + encodeURIComponent(state.filters.drafts[a].value);
        }
      }
    }
    dispatch("loadDrafts", filter_query);
  },
  eventsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.events) {
      if (state.filters.events[a].value !== null && state.filters.events[a].value !== '') {
        if (typeof state.filters.events[a].value === 'object') {
          filter_query += '&filter_' + state.filters.events[a].name + '=' + state.filters.events[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.events[a].name + '=' + encodeURIComponent(state.filters.events[a].value);
        }
      }
    }
    //dispatch("eventsGet", filter_query);
    const resource = "event";
    const apiRoute = "event";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  newslettersFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.newsletters) {
      if (state.filters.newsletters[a].value !== null && state.filters.newsletters[a].value !== '') {
        if (typeof state.filters.newsletters[a].value === 'object') {
          filter_query += '&filter_' + state.filters.newsletters[a].name + '=' + state.filters.newsletters[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.newsletters[a].name + '=' + encodeURIComponent(state.filters.newsletters[a].value);
        }
      }
    }
    //dispatch("eventsGet", filter_query);
    const resource = "newsletter";
    const apiRoute = "newsletter";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  projectsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.projects) {
      if (state.filters.projects[a].value !== null && state.filters.projects[a].value !== '') {
        if (typeof state.filters.projects[a].value === 'object') {
          filter_query += '&filter_' + state.filters.projects[a].name + '=' + state.filters.projects[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.projects[a].name + '=' + encodeURIComponent(state.filters.projects[a].value);
        }
      }
    }
    //dispatch("projectsGet", filter_query);
    const resource = "project";
    const apiRoute = "project";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  queuesFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.queues) {
      if (state.filters.queues[a].value !== null && state.filters.queues[a].value !== '') {
        if (typeof state.filters.queues[a].value === 'object') {
          filter_query += '&filter_' + state.filters.queues[a].name + '=' + state.filters.queues[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.queues[a].name + '=' + encodeURIComponent(state.filters.queues[a].value);
        }
      }
    }
    //dispatch("queuesGet", filter_query);
    const resource = "queue";
    const apiRoute = "queue";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  languageOverridesFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.languageOverrides) {
      if (state.filters.languageOverrides[a].value !== null && state.filters.languageOverrides[a].value !== '') {
        if (typeof state.filters.languageOverrides[a].value === 'object') {
          filter_query += '&filter_' + state.filters.languageOverrides[a].name + '=' + state.filters.languageOverrides[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.languageOverrides[a].name + '=' + encodeURIComponent(state.filters.languageOverrides[a].value);
        }
      }
    }
    //dispatch("languageOverridesGet", filter_query);
    const resource = "languageOverride";
    const apiRoute = "languageOverride";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  attestsFilter({dispatch, state}) {
    let filter_query = '';
    this.isFiltered = true;
    let a;
    for (a in state.filters.attests) {
      if (state.filters.attests[a].value !== null && state.filters.attests[a].value !== '') {
        if (typeof state.filters.attests[a].value === 'object') {
          filter_query += '&filter_' + state.filters.attests[a].name + '=' + state.filters.attests[a].value.id;
        } else {
          filter_query += '&filter_' + state.filters.attests[a].name + '=' + encodeURIComponent(state.filters.attests[a].value);
        }
      }
    }
    //dispatch("attestsGet", filter_query);
    const resource = "attest";
    const apiRoute = "attest";
    const filterQuery = filter_query;
    dispatch("listGet", {resource, apiRoute, filterQuery});
  },
  setPage({state, dispatch, getters}, payload) {
    let type, list, listName, filterName, offset;
    type = payload.type;
    list = payload.list;
    listName = payload.listName;
    filterName = payload.filterName;
    offset = payload.offset; // For direct page navigation
    if (type === 'next' && getters[listName].offset <= getters[listName].complete) {
      state.lists[list].offset = parseInt(getters[listName].offset) + parseInt(getters[listName].limit);
    } else if (type === 'previous' && getters[listName].offset >= getters[listName].limit) {
      state.lists[list].offset = parseInt(getters[listName].offset) - parseInt(getters[listName].limit);
    } else if (type === 'direct') {
      // Direct page navigation: set offset directly
      state.lists[list].offset = offset;
    }
    dispatch(filterName);
  }
}
const routeToFilterCategoryMap = {
  'Tasks': 'tasks', // Maps the "Tasks" route to the "tasks" filter category
  'Events': 'events',
  'customers': 'customers',
  'Documents': 'documents',
  'employees': 'employees',
  'products': 'products',
  // Add other mappings as needed
};
export const getters = {
  getNumberOfActiveFilters: (state) => (routeName) => {
    let count = 0;
    const ignoreKeys = ['direction', 'ordering', 'system', 'state', 'employee', 'search'];
    const filterCategory = routeToFilterCategoryMap[routeName]; // Use the passed route name
    if (filterCategory && state.filters[filterCategory]) {
      for (let filterKey in state.filters[filterCategory]) {
        if (ignoreKeys.includes(filterKey)) {
          continue;
        }
        const filter = state.filters[filterCategory][filterKey];
        if ((filter.value !== '' && filter.value !== null) ||
          (Array.isArray(filter.array) && filter.array.length > 0)) {
          count++;
        }
      }
    }
    return count;
  }
}

